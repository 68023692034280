html{

  scroll-behavior: smooth;

}

body{
  overflow: hidden;
}

.colorFondo{
  background-color: #111111;
}

.animatedBackground{
  background-image: url('./img/background.png');
  height: 100vh;
  width: 100%;
  position: fixed;
  z-index: -1;
}

.colorFondoNav{
  background-color: #111111a1;
  backdrop-filter: blur(10px);
}

.navElement{
  transition: color .7s;
}

.navElement:hover{
  color: #64ffda;
  transition: color .7s;
}

.navElement:focus{
color: #ccd6f6;
}

.navbar-toggler-icon{

background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 30 30'%3e%3cpath stroke='rgba%28100, 255, 218, 0.75%29' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e");

}

.cursor-pointer{
  cursor: pointer;
}

.divFlotante{

position: fixed;
display: flex;
left: 0;
bottom: 0;
width: 100%;
z-index: 1040;
pointer-events: none;
}

.verticalText{
writing-mode: vertical-lr;
}

.verticalLine{

  border-right: 2px solid #ccd6f6;
  height: 100px;
  width: 39px;
  border-radius: 5%;

}



.bg-green{
  color: #64ffda;
}

.bg-green-light{
  color: #89f3da;
}

.colorTexto{
  color: #8892b0;
}

.fontColor1{
  color: #ccd6f6;
}

.fontFamily1{
  font-family: monospace;
}

.fontFamily2{
  font-family: 'Roboto', sans-serif;
}

.mainContainer{
  margin-left: 150px;
  margin-right: 150px;
}

.titleContainer{

  padding-left: 7vw;
  margin-right: 7vw;
  margin-top: 18vh;
  margin-bottom: 35vh;

}

.newContainer{

  margin-left: 10vw;
  margin-right: 10vw;
  margin-top: 25vh;
  padding-top: 30vh;
}

.conocimientos{
  padding-top: 10vh;
}

.contactContainer{
padding-bottom: 100px;
}

/* Change img color on hover */
.imgPhoto{

  

  filter: sepia(100%) brightness(86%) hue-rotate(132deg) saturate(124%);
  -webkit-filter: sepia(100%) brightness(86%) hue-rotate(132deg) saturate(124%);
  -moz-filter: sepia(100%) brightness(86%) hue-rotate(132deg) saturate(124%);

  transition: filter .5s;

}

.imgPhoto:hover{

  filter: none;
  transition: filter .5s;

}

.imgPhotoContainer{

  box-shadow: #202020 20px 20px 0px -2px, #64ffda 20px 20px;
  transition: box-shadow .5s;
}

.imgPhotoContainer:hover{

  box-shadow: #202020 14px 14px 0px -2px, #64ffda 14px 14px;
  transition: box-shadow .5s;
}



.buttonGreen{

  background-color: rgba(255, 255, 255, 0);
  color: #64ffda;
  font-family: monospace;
  text-decoration: none;
  border: 1px solid #64ffda;
  border-radius: 5px;
  transition: background-color .5s;
}

.buttonGreen:hover{

  background-color: #64ffdb1c;
  
  
}

.imgProjects{
border: 2px solid #8892b06b;
border-radius: 2%;
transition: border .5s;
}

.projectTitle{
transition: color .5s;
}

.techDiv{

color: #64ffda;
text-align: center;
background-color: #64ffdb1e;
border-radius: 9999px;

}

.projectsDiv:hover{


.imgProjects{
border: 2px solid #b7c5ee6b;
}

.projectTitle{
color: #64ffda;
transition: color .5s;
}

}

.projectsLink{
text-decoration: none;
}


body::-webkit-scrollbar {
  width: 12px;               /* width of the entire scrollbar */
}

body::-webkit-scrollbar-track {
  background: #111111;        /* color of the tracking area */
}

body::-webkit-scrollbar-thumb {
  background-color: #6a6b6e;    /* color of the scroll thumb */
  border-radius: 20px;       /* roundness of the scroll thumb */
  border: 3px solid #0a192f;  /* creates padding around scroll thumb */
}



*::selection {
  background: #233554;
  color: #ccd6f6;

}

/* Firefox */
*::-moz-selection {
  background: #233554;
  color: #ccd6f6;

}



.hr1{

  border-top: 1px solid #8892b0;
  width: 8vw;

}


.skillContainer{

  background-color: #232323;

}



/* Google Icons */
.material-symbols-outlined {
font-variation-settings:
'FILL' 0,
'wght' 400,
'GRAD' 0,
'opsz' 48
}



.bigCircle{

  width: 35px;
  height: 35px;
  border: 3px solid #64ffda;
  position: absolute;
  border-radius: 50%;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;

}

.circle{

  width: 5px;
  height: 5px;
  background-color: #64ffda;
  border-radius: 50%;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  overflow: hidden;
  margin: auto;
  -webkit-animation: circle 5s linear infinite;

}

@-webkit-keyframes circle{

  0%{
      transform: rotate(0deg) translate(-8px) rotate(0deg);
  }
  100%{
      transform: rotate(360deg) translate(-8px) rotate(-360deg);
  }

}


#preloader{

  background: rgb(1, 5, 12);
  height: 100vh;
  width: 100%;
  position: fixed;
  z-index: 4000;
  top: 0;
  align-items: center;
  justify-content: center;

}



/* Load screen logo */



.leo {
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
}

.blue-orbit {
    width: 165px;
    height: 165px;
  border: 3px solid #91daffa5;
  -webkit-animation: spin3D 3s linear .2s infinite;
}

.green-orbit {
    width: 120px;
    height: 120px;
  border: 3px solid #64ffda;
  -webkit-animation: spin3D 2s linear 0s infinite;
}

.red-orbit {
    width: 90px;
    height: 90px;
  border: 3px solid #b0bcffa5;
  -webkit-animation: spin3D 1s linear 0s infinite;
}

.white-orbit {
    width: 60px;
    height: 60px;
  border: 3px solid #ffffff;
  -webkit-animation: spin3D 10s linear 0s infinite;
}

.w1 {
  transform: rotate3D(1, 1, 1, 90deg);
}

.w2 {
  transform: rotate3D(1, 2, .5, 90deg);
}

.w3 {
  transform: rotate3D(.5, 1, 2, 90deg);
}

.spinner-box {
  width: 300px;
  height: 300px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: transparent;
}

.three-quarter-spinner {
  width: 50px;
  height: 50px;
  border: 3px solid #64ffda;
  border-top: 3px solid transparent;
  border-radius: 50%;
  animation: spin .8s linear 0s infinite;
}

/* KEYFRAMES for Logo*/

@keyframes spin {
  from {
    transform: rotate(0);
  }
  to{
    transform: rotate(359deg);
  }
}

@keyframes spin3D {
  from {
    transform: rotate3d(.5,.5,.5, 360deg);
  }
  to{
    transform: rotate3d(0deg);
  }
}

@keyframes configure-clockwise {
  0% {
    transform: rotate(0);
  }
  25% {
    transform: rotate(90deg);
  }
  50% {
    transform: rotate(180deg);
  }
  75% {
    transform: rotate(270deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

@keyframes configure-xclockwise {
  0% {
    transform: rotate(45deg);
  }
  25% {
    transform: rotate(-45deg);
  }
  50% {
    transform: rotate(-135deg);
  }
  75% {
    transform: rotate(-225deg);
  }
  100% {
    transform: rotate(-315deg);
  }
}

@keyframes pulse {
  from {
    opacity: 1;
    transform: scale(1);
  }
  to {
    opacity: .25;
    transform: scale(.75);
  }
}


.floatDiv{

  text-align: right;
  
}

.bg-blue{
  background-color: #232323;
}

.newContainer2{

  margin-left: 7vw;
  margin-right: 7vw;
  margin-top: 25vh;
  padding-top: 30vh;
}

.projectsContainer{
padding-top: 13vh;
}


/* Media query para pantallas más pequeñas, como dispositivos móviles */
@media (max-width: 767px) {
.formacionContainer {
  float: none !important; /* Deja de flotar */
  width: 100% !important; /* Ocupa todo el ancho disponible */
  position: static !important;


}
}

@media (max-width: 500px) {

.techTextSize{
  font-size: 12px !important;
}
}

@media (max-width: 991px) {
.navbar-nav {
  text-align: center;
}

.navNumbersGreen {
  visibility: hidden;
}

.divFlotante{
  visibility: hidden;
}

.aboutMe{
  padding-top: 16vh;
}


.titleContainer{
  margin-top: 11vh;
}

.footer{
  padding-top: 14vh;
}

.techTextSize{
  font-size: 15px;
}

}

.hoverSizeEffect{
  transition: transform .2s; /* Animation */
}

.hoverSizeEffect:hover{
    transform: scale(1.1); /* (150% zoom - Note: if the zoom is too large, it will go outside of the viewport) */
}


.newAcordion{
  --bs-accordion-color: var(
    --bs-body-color);
      --bs-accordion-bg: #fff0;
      --bs-accordion-transition: color 0.15s ease-in-out,background-color 0.15s ease-in-out,border-color 0.15s ease-in-out,box-shadow 0.15s ease-in-out,border-radius 0.15s ease;
      --bs-accordion-border-color: #8892b000;
      --bs-accordion-border-width: 2px;
      --bs-accordion-border-radius: var(
    --bs-border-radius);
      --bs-accordion-inner-border-radius: calc(var(
    --bs-border-radius) - (var(--bs-border-width)));
      --bs-accordion-btn-padding-x: 1.25rem;
      --bs-accordion-btn-padding-y: 1rem;
      --bs-accordion-btn-color: var(
    --bs-body-color);
      --bs-accordion-btn-bg: var(
    --bs-accordion-bg);
      --bs-accordion-btn-icon: url("data:image/svg+xml,%3csvg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%270 0 16 16%27 fill=%27%23212529%27%3e%3cpath fill-rule=%27evenodd%27 d=%27M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z%27/%3e%3c/svg%3e");
      --bs-accordion-btn-icon-width: 1.25rem;
      --bs-accordion-btn-icon-transform: rotate(-180deg);
      --bs-accordion-btn-icon-transition: transform 0.2s ease-in-out;
      --bs-accordion-btn-active-icon: url("data:image/svg+xml,%3csvg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%270 0 16 16%27 fill=%27%230a58ca%27%3e%3cpath fill-rule=%27evenodd%27 d=%27M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z%27/%3e%3c/svg%3e");
      /* --bs-accordion-btn-focus-border-color: #64ffda; */
      --bs-accordion-btn-focus-box-shadow: 0 0 0 0.1rem #64ffdb00;
      --bs-accordion-body-padding-x: 1.25rem;
      --bs-accordion-body-padding-y: 1rem;
      --bs-accordion-active-color: #64ffda;
      --bs-accordion-active-bg: #cfe2ff00;
}