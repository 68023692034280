  .logoSvg {
    opacity: 0;
    animation: fadeIn 0.75s linear forwards;
    animation-delay: 1.7s;
  }
  
  .circle {
    stroke-dasharray: 500;
    stroke-dashoffset: 500;
    animation: stroke 1.4s ease-out forwards;
  }
  
  @keyframes stroke {
    to {
      stroke-dashoffset: 1000;
    }
  }
  @keyframes fadeIn {
    to {
      opacity: 1;
    }
  }